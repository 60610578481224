import { makeStyles, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { font, paths } from "../../../config/constants";
import { startContent } from "../../../content/start";
import { MintButton } from "../../../components/ui/buttons";

export default function HeaderSection() {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");
  const sm = useMediaQuery("(max-width:960px) and (min-width:600px)");
  // const md = useMediaQuery('(max-width:1280px) and (min-width: 960px)')
  // const lg = useMediaQuery('(max-width:1920px) and (min-width: 1280px)')
  // const xl = useMediaQuery('min-width: 1920px')

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        textAlign: "center",
      },
      image: {
        width: "100%",
        height: xs || sm ? "50vh" : "100vh",
        backgroundImage: `url("${paths.start.coverImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
      overlay: {
        marginTop: xs || sm ? -150 : -250,
        marginRight: xs || sm ? 20 : 100,
        marginLeft: xs || sm ? 20 : 100,
        fontSize: font.size.normal,
        whiteSpace: "pre-line",
      },
      text: {
        // marginTop: xs || sm ? 30 : 50
      },
    })
  );

  const classes = useStyles();

  const Banner = () => <div className={classes.image}></div>;
  const Text = () => <div className={classes.text}>{startContent.header}</div>;

  return (
    <div className={classes.root}>
      <Banner />
      <div className={classes.overlay}>
        <Text />
        <MintButton />
      </div>
    </div>
  );
}
