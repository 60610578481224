import { useCountdown } from "../../../../hooks/utilityHooks";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

interface IDateTimeDisplay {
  value: number;
  type: string;
}

interface IShowCounter {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

const CountdownTimer = ({ targetDate }: { targetDate: Date }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const useStyles = makeStyles(() =>
    createStyles({
      showCounter: {
        textShadow: "0 0 20px #48C8FF",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        marginTop: -40,
        marginBottom: 50,
      },
      value: {
        fontSize: xs ? "1.5rem" : "2rem",
        color: "white",
        fontWeight: "bold",
        marginBottom: 0,
      },
      block: {
        display: "inline-block",
        minWidth: xs ? 80 : 120,
        flexDirection: "column",
        verticalAlign: "middle",
      },
      blockType: {
        color: "#B1CDF1",
        fontSize: xs ? "1rem" : "1.2rem",
        fontWeight: 400,
      },
    })
  );
  const classes = useStyles();

  const ExpiredNotice = () => {
    return (
      <>
        <br />
        <h3>The game has started!</h3>
        <p>The winners will be presented shortly</p>
      </>
    );
  };

  const DateTimeDisplay = ({ value, type }: IDateTimeDisplay) => {
    return (
      <div className={classes.block}>
        <p className={classes.value}>{value}</p>
        <span className={classes.blockType}>{type}</span>
      </div>
    );
  };

  const ShowCounter = ({ days, hours, minutes, seconds }: IShowCounter) => {
    return (
      <div className={classes.showCounter}>
        <DateTimeDisplay value={days} type={"Days"} />
        <DateTimeDisplay value={hours} type={"Hours"} />
        <DateTimeDisplay value={minutes} type={"Mins"} />
        <DateTimeDisplay value={seconds} type={"Seconds"} />
      </div>
    );
  };

  if (days + hours + minutes + seconds <= 0) {
    window.location.reload();
    return null;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
