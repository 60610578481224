import Web3 from "web3";
import Onboard from "bnc-onboard";
import { networkId, chainId, apis } from "../config/constants";
declare var window: any;

const getWeb3 = async () => {
  if (window.ethereum) {
    return new Web3(window.ethereum);
  } else if (window.web3) {
    return window.web3;
  }
  return undefined;
};

const connectWallet = async () => {
  try {
    const onboard = Onboard({
      dappId: apis.blocknative.key,
      networkId: networkId,
      darkMode: true,
      subscriptions: {
        wallet: (wallet) => {
          // const web3 = new Web3(wallet.provider)
          console.log(`Connected to ${wallet.name}`);
        },
      },
    });
    await onboard.walletSelect();
    await onboard.walletCheck();
    //   success()
  } catch (error) {
    console.error(error);
  }
};

const onAccountChange = (onChange: (address: string) => void) => {
  try {
    window.ethereum.on("accountsChanged", (accounts: Array<string>) =>
      onChange(accounts[0])
    );
  } catch (error: any) {
    console.log("window.ethereum not found, could not set account!");
  }
};

const onNetworkChange = (onChange: (_networkId: string) => void) => {
  try {
    window.ethereum.on("chainChanged", (_networkId: string) => {
      console.log("chainChanged", _networkId);
      onChange(_networkId);
    });
  } catch (error: any) {
    console.log("window.ethereum not found, could not set network!");
  }
};

const switchNetwork = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [
        {
          chainId: chainId,
        },
      ],
    });
  } catch (error: any) {
    console.log("window.ethereum not found, could not switch network!");
  }
};

const getAccounts = async () => {
  const web3 = await getWeb3();
  return await web3.eth.getAccounts();
};

const getAccount = async () => {
  if (!window.ethereum) return;
  const accounts = await getAccounts();
  return accounts[0];
};

const getSelectedAccount = () => window?.ethereum?.selectedAddress ?? null;
const getNetworkId = () => window?.ethereum?.networkVersion ?? null;
const isCorrectNetwork = () => networkId.toString() === getNetworkId();

export {
  getWeb3,
  connectWallet,
  onAccountChange,
  onNetworkChange,
  switchNetwork,
  getAccount,
  getSelectedAccount,
  getNetworkId,
  isCorrectNetwork,
};
