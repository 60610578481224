import { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import CircularProgress from "@mui/material/CircularProgress";
import Arena from "../../../components/arena/Arena";
import { Spacing } from "../../../components/ui/UI";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { getArenaById } from "../../../config/arenas";
import _DegenArena from "../../../services/DegenArena";
import Gladiator from "../../../services/Gladiator";
import CountdownTimer from "./components/CountdownTimer";
import ConnectWallet from "./components/ConnectWallet";
import SwitchNetwork from "./components/SwitchNetwork";
// import SelectGladiator from "./components/SelectGladiator";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CreateDuel from "./components/CreateDuel";
import Duels from "./components/Duels";
import {
  getAccount,
  onAccountChange,
  onNetworkChange,
  isCorrectNetwork,
} from "../../../services/Web3";
import { IDuelSet } from "../../../services/DegenArena";
import { paths } from "../../../config/constants";

export default function DegenArena() {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");
  const sm = useMediaQuery("(max-width:960px) and (min-width:600px)");

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        textAlign: "center",
        background: "linear-gradient(45deg, #171717 0%, #000000 70%)",
      },
    })
  );

  const classes = useStyles();
  const arena = getArenaById("degen");
  const [closesAt, setClosesAt] = useState<number | undefined>();
  const [ownerTokens, setOwnerTokens] = useState([]);
  const [duels, setDuels] = useState<IDuelSet>();
  const [account, setAccount] = useState<string | undefined>();

  const onAccountChangeListner = () => {
    onAccountChange((_account: string) => window.location.reload());
  };

  const onNetworkChangeListner = () => {
    onNetworkChange((_networkId: string) => window.location.reload());
  };

  const ArenaOpenStatus = () => {
    const useStyles = makeStyles(() =>
      createStyles({
        title: {
          marginBottom: 30,
        },
      })
    );

    const classes = useStyles();

    if (closesAt === undefined) return null;
    if (closesAt === 0)
      return <h3>🚫 Arena is closed, a new game will start soon...</h3>;
    return closesAt! > new Date().getTime() ? (
      <>
        <h3 className={classes.title}>Battle begins in:</h3>
        <CountdownTimer targetDate={new Date(closesAt!)} />
      </>
    ) : (
      <>
        <h2>⚔️ The game has started</h2>
        <p>🏆 Winners will be announced shortly</p>
      </>
    );
  };

  const CorrectNetwork = () => {
    if (!account) return <ConnectWallet />;
    return (
      <>
        <ArenaOpenStatus />
        {closesAt! > new Date().getTime() && (
          <CreateDuel ownerTokens={ownerTokens} />
        )}
        {duels ? (
          <>
            <Duels
              account={account}
              title="⚔️ Your duels"
              ownerTokens={ownerTokens}
              duels={[...duels.account].reverse()}
              all={duels.all}
            />
            <Duels
              account={account}
              title="⚔️ All duels"
              ownerTokens={ownerTokens}
              duels={[...duels.all].reverse()}
              all={duels.all}
            />
          </>
        ) : (
          <>
            <br />
            <Loading />
          </>
        )}
      </>
    );
  };

  const fetchOnChainData = async () => {
    if (!isCorrectNetwork()) return;
    const _account = await getAccount();
    if (!_account) return;
    setAccount(_account);
    setClosesAt(await _DegenArena.arenaClosesAt());
    setOwnerTokens(await Gladiator.getTokensByOwner());
    setDuels(await _DegenArena.getDuels());
  };

  const Loading = () => <CircularProgress color="secondary" />;

  const Background = () => {
    const useStyles = makeStyles(() =>
      createStyles({
        root: {
          width: "100%",
          height: "100vh",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundImage: `url("${paths.degenArena.closed}")`,
        },
      })
    );
    const classes = useStyles();
    return xs || sm ? null : <div className={classes.root}></div>;
  };

  useEffect(() => {
    onNetworkChangeListner();
    onAccountChangeListner();
    fetchOnChainData();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Background />
      <Container>
        <Spacing />
        <Arena
          isLeftPadding={true}
          id={arena.id}
          title={arena.title}
          description={arena.description}
          image={arena.logo}
        />
        {isCorrectNetwork() ? <CorrectNetwork /> : <SwitchNetwork />}
        <Spacing />
      </Container>
    </div>
  );
}
