import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { useNavigate } from "react-router-dom";
import { paths } from "../../config/constants";

type ArenaProps = {
  id?: string;
  title: string;
  description: string;
  image?: string;
  isLeftPadding?: boolean;
};

export default function Arena({
  id,
  title,
  description,
  image,
  isLeftPadding = false,
}: ArenaProps) {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");
  const sm = useMediaQuery("(max-width:960px) and (min-width:600px)");

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        // cursor: id ? "pointer" : "normal",
        marginBottom: 50,
      },
      imgContainer: {
        alignItems: "center",
        justifyContent: "center",
        background: "transparent",
      },
      img: {
        maxWidth: "100%",
        maxHeight: 350,
      },
      title: {
        marginTop: -5,
      },
      description: {
        marginRight: xs ? 0 : 60,
        marginLeft: xs || !isLeftPadding ? 0 : 60,
        whiteSpace: "pre-line",
      },
      spacing: {
        width: "100%",
        height: 90,
      },
    })
  );

  const classes = useStyles();
  //   const navigate = useNavigate();

  //   const goToArena = () => {
  //     if (!id) return;
  //     navigate(`/degen-arena`);
  //   };

  return (
    <div className={classes.root} onClick={() => undefined}>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item={true}>
        {xs ||
          (sm && (
            <div className={classes.imgContainer}>
              {image && (
                <img
                  alt=""
                  className={classes.img}
                  src={image ?? paths.placeholder}
                />
              )}
            </div>
          ))}
      </Grid>
      <Grid xs={12} sm={12} md={12} lg={12} xl={12} item={true}>
        <h2 className={classes.title}>{title}</h2>
        <p className={classes.description}>{description}</p>
      </Grid>
    </div>
  );
}
