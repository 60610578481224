import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { links } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import { footerContent } from "../../content/footer";
import { log } from "../../admin";

export default function Footer() {
  const useStyles = makeStyles({
    root: {
      minHeight: 250,
      width: "100%",
      background: "linear-gradient(80deg, #252728 0%, #101415 100%)",
      borderTop: "2px solid #282828",
      borderBottom: "2px solid #2c2e2e",
      boxShadow: "0px 0px 8px #000",
      bottom: 0,
      marginTop: 90,
      paddingTop: 30,
      textAlign: "left",
      whiteSpace: "pre-line",
    },
    gridOne: {
      paddingRight: 40,
      marginBottom: 50,
    },
    gridTwo: {
      paddingRight: 20,
      marginBottom: 50,
    },
    gridThree: {
      marginBottom: 50,
    },
    title: {
      fontSize: 18,
      fontWeight: "bold",
    },
    text: {
      fontSize: 18,
      marginTop: 20,
    },
    item: {
      fontSize: 18,
      marginTop: 20,
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Container>
        <Grid container justifyContent="center">
          <Grid
            className={classes.gridOne}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={6}
          >
            <div className={classes.title}>{footerContent.title}</div>
            <div className={classes.text}>{footerContent.text}</div>
          </Grid>
          <Grid
            className={classes.gridThree}
            item
            xs={12}
            sm={6}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.title}>Communiy</div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("discord");
                window.open(links.discord, "_blank");
              }}
            >
              Discord
            </div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("twitter");
                window.open(links.twitter, "_blank");
              }}
            >
              Twitter
            </div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("opensea");
                window.open(links.opensea, "_blank");
              }}
            >
              Opensea
            </div>
          </Grid>
          <Grid
            className={classes.gridThree}
            item
            xs={6}
            sm={6}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.title}>Project</div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("whitepaper");
                window.open(links.gitbook, "_blank");
              }}
            >
              Whitepaper
            </div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("team");
                navigate(`/team`);
              }}
            >
              Team
            </div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("github");
                window.open(links.github, "_blank");
              }}
            >
              Github
            </div>
          </Grid>
          <Grid
            className={classes.gridTwo}
            item
            xs={6}
            sm={6}
            md={2}
            lg={2}
            xl={2}
          >
            <div className={classes.title}>Press</div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("medium");
                alert("coming soon...");
              }}
            >
              Medium
            </div>
            <div
              className={classes.item}
              onClick={() => {
                log.clicked("contact");
                window.open("mailto:info@metagladiators.co");
              }}
            >
              Contact us
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
