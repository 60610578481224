import { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import ConnectedWallet from "./components/ConnectedWallet";
import ConnectWallet from "./components/ConnectWallet";
import SwitchNetwork from "./components/SwitchNetwork";
import CountdownTimer from "./components/CountdownTimer";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  getAccount,
  onAccountChange,
  onNetworkChange,
  isCorrectNetwork,
} from "../../services/Web3";
import Gladiator from "../../services/Gladiator";
import { getWeb3 } from "../../services/Web3";
import { font, mint } from "../../config/constants";
import { getShortAddress } from "../../hooks/utilityHooks";
import { numberOfWhitelistMints } from "../../config/lists/whitelist";

export default function Mint() {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");
  const sm = useMediaQuery("(max-width:960px) and (min-width:600px)");

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        paddingTop: 100,
        paddingBottom: 150,
        background: "black",
        textAlign: "center",
      },
      account: {
        fontFamily: '"Lucida Console", "Courier New", monospace',
        fontSize: font.size.small,
      },
      tribesImage: {
        width: xs || sm ? "100%" : "60%",
        heigth: "auto",
      },
    })
  );

  const classes = useStyles();
  const [isWeb3, setWeb3] = useState(true);
  const [account, setAccount] = useState("");
  const [isMintOpen, setMintOpen] = useState(false);
  const [totalSupply, setTotalSupply] = useState<number>(0);
  const [mintLimit, setMintLimit] = useState<number>(0);
  const [isPublicMintOpen, setPublicMintOpen] = useState(false);
  const [noOfFreeMints, setNoOfFreeMints] = useState<number | undefined>();
  const [mintsLeft, setMintsLeft] = useState<number>(0);
  const [isWhitelist, setWhitelist] = useState<boolean | undefined>(undefined);
  const [isWalletConnected, setWalletConnected] = useState<
    boolean | undefined
  >();

  useEffect(() => {
    (async () => {
      if (!(await getWeb3())) return setWeb3(false);
      onNetworkChangeListner();
      onAccountChangeListner();
      checkAccount();
      checkMintOpen();
      checkFreelist();
      checkWhitelist();
      checkPublicMint();
      checkMintsLeft();
      checkTotalSupply();
      checkMintLimit();
    })();
  });

  const checkMintLimit = async () => {
    const _mintLimit = await Gladiator.getMintLimit();
    setMintLimit(_mintLimit);
  };

  const checkMintsLeft = async () => {
    const _mintsLeft = await Gladiator.mintsLeft();
    setMintsLeft(numberOfWhitelistMints - _mintsLeft);
  };

  const checkAccount = async () => {
    const _account = await getAccount();
    setWalletConnected(_account !== undefined);
    setAccount(_account !== undefined ? _account : "");
  };

  const onAccountChangeListner = () => {
    onAccountChange((_account: string) => {
      setWalletConnected(_account !== undefined);
      setAccount(_account !== undefined ? _account : "");
      checkWhitelist();
    });
  };

  const onNetworkChangeListner = () => {
    onNetworkChange((_networkId: string) => {
      window.location.reload();
    });
  };

  const checkTotalSupply = async () => {
    if (!isCorrectNetwork()) return;
    setTotalSupply(await Gladiator.getTotalSupply());
  };
  const checkMintOpen = async () => {
    if (!isCorrectNetwork()) return;
    setMintOpen(await Gladiator.isMintOpen());
  };

  const checkPublicMint = async () => {
    if (!isCorrectNetwork()) return;
    setPublicMintOpen(await Gladiator.isPublicMintOpen());
  };

  const checkFreelist = async () => {
    if (!isCorrectNetwork()) return;
    setNoOfFreeMints(await Gladiator.getNumberOfFreeMints());
  };

  const checkWhitelist = async () => {
    if (!isCorrectNetwork()) return;
    setWhitelist(await Gladiator.checkWhitelist());
  };

  const Title = () => {
    if (!isWeb3) return null;
    if (totalSupply === mintLimit)
      return (
        <>
          <h3>All {totalSupply} in this batch are minted!</h3>
          <p>Remaining {5000 - mintLimit} TBA in the Discord.</p>
        </>
      );
    return !isMintOpen ? (
      <CountdownTimer targetDate={mint.date} />
    ) : (
      <h3>
        Mint is open!{" "}
        {totalSupply > 350 ? `(${mintLimit - totalSupply} left)` : ""}
      </h3>
    );
  };

  const TribesImage = () => (
    <img
      alt=""
      src="img/mint/mint-banner.png"
      className={classes.tribesImage}
    />
  );

  // const Subtitle = () => <div className={classes.subtitle}>Mint date will be announced in the discord</div>

  const Account = () =>
    account !== "" ? (
      <div className={classes.account}>
        <strong>Account: </strong>
        {getShortAddress(account)}
      </div>
    ) : null;

  const FreeMints = () => {
    if (!isWalletConnected) {
      return null;
    }
    if (noOfFreeMints === undefined) {
      return null;
    }
    return noOfFreeMints > 0 ? (
      <p>
        🔥 You have <strong>{noOfFreeMints} free mints</strong>
      </p>
    ) : null;
  };

  const Whitelist = () => {
    if (!isWalletConnected) return null;
    if (isWhitelist === undefined) return null;
    if (isPublicMintOpen) return null;
    return isWhitelist ? (
      <p>
        ✅ You are on the whitelist {mintsLeft > 0 ? "and" : "but"} got{" "}
        {mintsLeft} mints left.{" "}
      </p>
    ) : (
      <p>✋ You are not on the whitelist</p>
    );
  };

  const PublicMint = () => {
    return isPublicMintOpen ? (
      <p>
        You have <strong>{mintsLeft} mints</strong> left
      </p>
    ) : null;
  };

  const CorrectNetwork = () => {
    switch (isWalletConnected) {
      case true:
        return (
          <ConnectedWallet
            isMintOpen={isMintOpen}
            isMintDone={totalSupply === mintLimit}
            noOfFreeMints={noOfFreeMints}
            isWhitelist={isWhitelist}
          />
        );
      case false:
        return <ConnectWallet />;
      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Container>
        <TribesImage />
        <Title />
        {totalSupply < mintLimit && (
          <>
            <Account />
            <FreeMints />
            <Whitelist />
            <PublicMint />
          </>
        )}
        {!isCorrectNetwork() ? <SwitchNetwork /> : <CorrectNetwork />}
      </Container>
    </div>
  );
}
